const APP_TYPE_TARGET = [
  { value: 1, text: '公募' },
  { value: 2, text: '推薦のみ' },
];

const BUNRI = {
  0: '',
  1: '文',
  2: '理',
};

const SERIES_APP_FILES = {
  // award
  1: [
    { type: 1, fileKey: 'file1', label: '募集要項' },
    { type: 1, fileKey: 'file2', label: 'テスト' },
  ],
  // 調査研究助成
  2: [
    { type: 1, fileKey: 'file1', label: '研究内容' },
    { type: 1, fileKey: 'file2', label: '推薦書' },
    { type: 1, fileKey: 'file3', label: '助成金使途明細' },
  ],
  // 国際会議開催助成
  3: [
    { type: 1, fileKey: 'file1', label: '開催計画書' },
    { type: 1, fileKey: 'file2', label: '助成金使途明細' },
  ],
  // 社会的文化的諸活動助成
  4: [
    { type: 1, fileKey: 'file1', label: '活動、団体概要' },
    { type: 1, fileKey: 'file2', label: '助成金使途明細' },
  ],
  // 日本人留学生助成
  5: [
    { type: 1, fileKey: 'file1', label: '募集要項' },
    { type: 1, fileKey: 'file2', label: '申請書1' },
    { type: 1, fileKey: 'file3', label: '申請書2' },
    { type: 1, fileKey: 'file4', label: '指導教授の推薦書' },
    { type: 1, fileKey: 'file5', label: '大学からの推薦書' },
  ],
  // 外国人留学生助成
  6: [
    { type: 1, fileKey: 'file1', label: '募集要項' },
    { type: 1, fileKey: 'file2', label: 'Application Form1' },
    { type: 1, fileKey: 'file3', label: 'Application Form2' },
    { type: 1, fileKey: 'file4', label: '指導教授の推薦書' },
    { type: 1, fileKey: 'file5', label: '大学からの推薦書' },
  ],
  // 語学留学生助成
  7: [
    { type: 1, fileKey: 'file1', label: '募集要項' },
    { type: 1, fileKey: 'file2', label: '申請書1' },
    { type: 1, fileKey: 'file3', label: '申請書2' },
    { type: 1, fileKey: 'file4', label: '指導教授の推薦書' },
    { type: 1, fileKey: 'file5', label: '大学からの推薦書' },
  ],
  // 目的型調査研究助成
  8: [
    { type: 1, fileKey: 'file1', label: '研究内容' },
    { type: 1, fileKey: 'file2', label: '推薦書（機関長）' },
    { type: 1, fileKey: 'file3', label: '助成金使途明細' },
  ],
  // 目的型諸活動助成
  9: [
    { type: 1, fileKey: 'file1', label: '活動の内容・団体概要' },
    { type: 1, fileKey: 'file2', label: '資金調達・助成金使途内訳' },
  ],
};


const SERIES_INTERIM_FILES = {
  // award
  1: [],
  // 調査研究助成
  2: [
    { type: 2, fileKey: 'interimRepFile1', label: '進捗状況報告書' },
  ],
  // 国際会議開催助成
  3: [],
  // 社会的文化的諸活動助成
  4: [],
  // 日本人留学生助成
  5: [
    { type: 2, fileKey: 'interimRepFile1', label: '進捗状況報告書' },
  ],
  // 外国人留学生助成
  6: [],
  // 語学留学生助成
  7: [],
  // 目的型調査研究助成
  8: [
    { type: 2, fileKey: 'interimRepFile1', label: '進捗状況報告書' },
  ],
  // 目的型諸活動助成
  9: [
    { type: 2, fileKey: 'interimRepFile1', label: '進捗状況報告書' },
  ],
};

const SERIES_REPORT_FILES = {
  // award
  1: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 調査研究助成
  2: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 国際会議開催助成
  3: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 社会的文化的諸活動助成
  4: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 日本人留学生助成
  5: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 外国人留学生助成
  6: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 語学留学生助成
  7: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 目的型調査研究助成
  8: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
  // 目的型諸活動助成
  9: [
    { type: 3, fileKey: 'reportFile1', label: '成果報告書' },
  ],
};

const SERIES_REQUIRED_DOC_FILES = {
  // award
  1: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 調査研究助成
  2: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 国際会議開催助成
  3: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 社会的文化的諸活動助成
  4: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 日本人留学生助成
  5: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 外国人留学生助成
  6: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 語学留学生助成
  7: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 目的型調査研究助成
  8: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
  // 目的型諸活動助成
  9: [
    { type: 4, fileKey: 'requiredDocFile1', label: '助成手続き書類' },
  ],
};

const SERIES_CHANGE_PLAN_FILES = {
  // award
  1: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 調査研究助成
  2: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 国際会議開催助成
  3: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 社会的文化的諸活動助成
  4: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 日本人留学生助成
  5: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 外国人留学生助成
  6: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 語学留学生助成
  7: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 目的型調査研究助成
  8: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
  // 目的型諸活動助成
  9: [
    { type: 5, fileKey: 'changePlanFile1', label: '計画変更申請' },
  ],
};
export default {
  APP_TYPE_TARGET,
  SERIES_APP_FILES,
  SERIES_INTERIM_FILES,
  SERIES_REPORT_FILES,
  BUNRI,
  SERIES_REQUIRED_DOC_FILES,
  SERIES_CHANGE_PLAN_FILES,
};
