
const APP_STATUS = {
  UNSUBMIT: 0,
  SUBMIT: 1,
  RETURN: 2,
  RECEIPT: 3,
  EVALUATED: 4,
  TEMPSAVE: 5,
  ADOPTED: 10,
  UNADOPTED: 11,
  REJECTED: 12,
  WITHDREW: 13,
  SUBSTITUTE: 14,
  DECLINE: 15,
  ADWITHDREW: 16,
};

const APP_STATUS_TEXTS = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.EVALUATED]: '審査済',
  [APP_STATUS.TEMPSAVE]: '一時保存',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.WITHDREW]: '取下',
  [APP_STATUS.SUBSTITUTE]: '補欠',
  [APP_STATUS.DECLINE]: '辞退',
  [APP_STATUS.UNSUBMIT]: '未提出',
  [APP_STATUS.ADWITHDREW]: '取り下げ',
};

// 管理画面で表示するステータスのセレクトボックス値
const CMS_APP_STATUS_OPTION = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.SUBSTITUTE]: '補欠',
  [APP_STATUS.ADWITHDREW]: '取り下げ',
  [APP_STATUS.DECLINE]: '辞退',
};
const CMS_APP_STATUS_SORT_LIST = {
  [APP_STATUS.SUBMIT]: 1,
  [APP_STATUS.RETURN]: 2,
  [APP_STATUS.REJECTED]: 4,
  [APP_STATUS.RECEIPT]: 3,
  [APP_STATUS.SUBSTITUTE]: 5,
  [APP_STATUS.ADWITHDREW]: 6,
  [APP_STATUS.DECLINE]: 7,
};
const CMS_APP_STATUS_OPTION_4_LIST = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
  [APP_STATUS.SUBSTITUTE]: '補欠',
  [APP_STATUS.ADWITHDREW]: '取り下げ',
  [APP_STATUS.DECLINE]: '辞退',
};
const CMS_APP_STATUS_OPTION_4_SORT_LIST = {
  [APP_STATUS.SUBMIT]: 1,
  [APP_STATUS.RECEIPT]: 2,
  [APP_STATUS.RETURN]: 3,
  [APP_STATUS.REJECTED]: 4,
  [APP_STATUS.ADOPTED]: 5,
  [APP_STATUS.UNADOPTED]: 6,
  [APP_STATUS.SUBSTITUTE]: 7,
  [APP_STATUS.ADWITHDREW]: 8,
  [APP_STATUS.DECLINE]: 9,
};
const CMS_APP_STATUS_REJECTED_OPTION_2_LIST = {
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.REJECTED]: '棄却',
};

const CMS_REP_STATUS_OPTION = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '受領',
};

const CMS_REP_STATUS_OPTION_4_LIST = {
  [APP_STATUS.UNSUBMIT]: '未提出',
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.RETURN]: '差戻',
};

const CMS_APP_AFTER_STATUS = {
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
  [APP_STATUS.SUBSTITUTE]: '補欠',
  [APP_STATUS.ADWITHDREW]: '取り下げ',
  [APP_STATUS.DECLINE]: '辞退',
};
const CMS_APP_AFTER_SORT_STATUS = {
  [APP_STATUS.REJECTED]: 1,
  [APP_STATUS.ADOPTED]: 2,
  [APP_STATUS.UNADOPTED]: 3,
  [APP_STATUS.SUBSTITUTE]: 4,
  [APP_STATUS.ADWITHDREW]: 5,
  [APP_STATUS.DECLINE]: 6,
};
// 返却理由を持つ（表示や書き込みができる）ステータス
const HAS_REASON_STATUS = [
  APP_STATUS.RETURN,
  APP_STATUS.REJECTED,
  APP_STATUS.UNADOPTED,
  APP_STATUS.RECEIPT,
  APP_STATUS.ADOPTED,
  APP_STATUS.SUBSTITUTE,
  APP_STATUS.DECLINE,
  APP_STATUS.ADWITHDREW,
];
const HAS_APP_REASON_STATUS = [
  APP_STATUS.RETURN,
  APP_STATUS.UNADOPTED,
  APP_STATUS.RECEIPT,
  APP_STATUS.ADOPTED,
  APP_STATUS.SUBSTITUTE,
  APP_STATUS.DECLINE,
  APP_STATUS.ADWITHDREW,
  APP_STATUS.REJECTED,
];
// 一般画面で現在のステータスとかを表示するステータス
const GAS_VIEW_CONTROL_STATUS = [
  APP_STATUS.SUBMIT,
  APP_STATUS.RETURN,
  APP_STATUS.RECEIPT,
  APP_STATUS.EVALUATED,
  APP_STATUS.ADOPTED,
  APP_STATUS.UNADOPTED,
  APP_STATUS.REJECTED,
];

const FROM_STATUS = {
  INIT: 1, // 新規登録
  EDIT: 2, // 編集登録
  VIEW: 3, // 閲覧オンリー
  NONE: 0, // 閲覧さえ不可
};

const CHANGE_DATA_STATUS = {
  CHANGE: 1, // 変更あり
  NONE: 0, // 変更なし
};

const CHANGE_DATA_STATUS_TEXT = {
  [CHANGE_DATA_STATUS.CHANGE]: 'あり', // 変更あり
  [CHANGE_DATA_STATUS.NONE]: 'なし', // 変更なし
};

// 審査用ステータス
const JUDGMENT_STATUS_OPTION = {
  1: '採択',
  0: '不採択',
  [APP_STATUS.SUBSTITUTE]: '補欠',
  [APP_STATUS.DECLINE]: '辞退',
  [APP_STATUS.ADWITHDREW]: '取り下げ',
};

// 大学申請で表示するステータスのセレクトボックス値
const UNI_APP_STATUS_OPTION = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.RECEIPT]: '受領',
  [APP_STATUS.TEMPSAVE]: '一時保存',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
};
const UNI_REP_STATUS_OPTION = {
  [APP_STATUS.UNSUBMIT]: '未提出',
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '受領',
  // [APP_STATUS.TEMPSAVE]: '一時保存',
};
const UNI_CHANGE_APP_STATUS_OPTION = {
  [APP_STATUS.UNSUBMIT]: '未提出',
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '承認',
  // [APP_STATUS.TEMPSAVE]: '一時保存',
};
const APP_CHANGE_STATUS_TEXTS = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '承認',
  [APP_STATUS.EVALUATED]: '審査済',
  [APP_STATUS.TEMPSAVE]: '一時保存',
  [APP_STATUS.ADOPTED]: '採択',
  [APP_STATUS.UNADOPTED]: '不採択',
  [APP_STATUS.REJECTED]: '棄却',
  [APP_STATUS.WITHDREW]: '取下',
};
const CMS_CHANGE_STATUS_OPTION = {
  [APP_STATUS.SUBMIT]: '確認中',
  [APP_STATUS.RETURN]: '差戻',
  [APP_STATUS.RECEIPT]: '承認',
};
export default {
  APP_STATUS,
  APP_STATUS_TEXTS,
  CMS_APP_STATUS_OPTION,
  CMS_APP_STATUS_OPTION_4_LIST,
  CMS_APP_STATUS_REJECTED_OPTION_2_LIST,
  CMS_REP_STATUS_OPTION,
  CMS_REP_STATUS_OPTION_4_LIST,
  HAS_REASON_STATUS,
  GAS_VIEW_CONTROL_STATUS,
  FROM_STATUS,
  CHANGE_DATA_STATUS,
  CHANGE_DATA_STATUS_TEXT,
  JUDGMENT_STATUS_OPTION,
  UNI_APP_STATUS_OPTION,
  UNI_REP_STATUS_OPTION,
  APP_CHANGE_STATUS_TEXTS,
  CMS_CHANGE_STATUS_OPTION,
  UNI_CHANGE_APP_STATUS_OPTION,
  HAS_APP_REASON_STATUS,
  CMS_APP_AFTER_STATUS,
  CMS_APP_STATUS_SORT_LIST,
  CMS_APP_AFTER_SORT_STATUS,
  CMS_APP_STATUS_OPTION_4_SORT_LIST,
};
