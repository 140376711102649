<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">合否設定 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serch-wrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search-area flex flexCenter">

          <div class="search-middle inlineBlock search-space">
            <div>年度</div>
            <div>
              <b-form-select
                @change="resetFilter(type='year')"
                :options="yearList"
                :value="filter.year"
                @input="setFilter('year', $event)"
                v-model="filter.year">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="search-middle-long inlineBlock search-space">
            <div>助成プログラム</div>
            <div>
                <!-- :options="apptypeListData[filter.year]" -->
              <b-form-select
                @change="resetFilter(type='apptype')"
                :value="filter.apptype"
                @input="setFilter('apptype', $event);setBunri($event)"
                :options="apptypeList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="search-middle-long inlineBlock search-space">
            <div>審査段階</div>
            <div>
              <b-form-select
                :value="filter.stage"
                @input="setFilter('stage', $event);fetchAppList()"
                :options="stageList">
                <template v-slot:first>
                  <option :value="null">
                    <span v-if="stageList.length > 0">-- 未選択 --</span>
                    <span v-if="stageList.length <= 0">助成プログラムを選択してください</span>
                  </option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock search-space">
            <div>文理区分</div>
            <div>
              <b-form-select
                :value="filter.bunri"
                @input="setFilter('bunri', $event)"
                :options="bunriOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                  <!-- <option :value="null">区分なし</option> -->
                </template>
              </b-form-select>
            </div>
          </div>
          <!-- ステータスに変更？ -->
          <div class="inlineBlock">
            <div>合否</div>
            <div>
              <b-form-select
                :value="filter.pass"
                @input="setFilter('pass', $event)"
                :options="judgmentOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='cmsJudgmentTable'
          table-class="cmsJudgmentTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="filter"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-null-last="true"
          :sort-compare="sortCompare"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">{{noDataMsg}}</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1 select-pop-over-trigger">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 select-pop-over" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 select-pop-over" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 select-pop-over" @click="allSelect">全選択</p>
                <p class="mb-0 select-pop-over" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedAppIdList($event)"
                :checked="selectedAppIdList"
                :value="row.item.app_id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(done)="row">
            <span v-if="row.item.count_assign>0">
              {{row.item.done}}({{row.item.done_eval}} / {{row.item.count_assign}})
            </span>
          </template>
          <template v-slot:cell(pass)="row">
            {{judgmentStatusText[row.item.pass] || ''}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="`/cms/applications/judgment/edit/${row.item.app_id}/${row.item.stage_id}`">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportCsv()"
          >CSV出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportExcel()"
          >Excel出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
           v-b-modal.judgementModal
          >一括合否設定</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg mr-2 bold ml-2"
          :disabled="selectedAppIdList.length < 1"
          to="/cms/applications/judgment/mail/">メール送信</b-button>
          <!-- :disabled="selectedAppIdList.length < 1" -->
      </div>
    </div>

    <b-modal
      id="judgementModal"
      title-class="titleMsg"
      body-class="modalBox"
      content-class="p-2"
      title=""
      size="lg"
      ok-title=""
      ok-variant="secondary"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
    >
      <div class="my-3 flex flexCenter mx-auto">
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold"
          @click="judgment(1)"
          >{{judgmentStatus[1]}}</b-button>
        <b-button
          class="btn btn-primary btn-lg bold ml-2"
          @click="judgment(0)"
          >{{judgmentStatus[0]}}</b-button>
        <b-button
          v-if="isShowStatus"
          class="btn btn-primary btn-lg bold ml-2"
          @click="judgment(14)"
          >{{judgmentStatus[14]}}</b-button>
        <b-button
          class="btn btn-primary btn-lg bold ml-2"
          @click="judgment(15)"
          >{{judgmentStatus[15]}}</b-button>
        <b-button
          class="btn btn-primary btn-lg bold ml-2"
          @click="judgment(16)"
          >{{judgmentStatus[16]}}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import CONST_APPTYPE from '@/constants/apptypes';
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsJudgmentList',
  data() {
    return {
      yearList: [],
      dbAppList: [],
      apptypeListData: {},
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'institution', label: '所属', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'bunri', label: '文理区分', sortable: true },
        { key: 'done', label: '審査状況', sortable: true },
        { key: 'score', label: '得点', sortable: true },
        { key: 'pass', label: '合否', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      hasBunri: false,
      authAlternate: [],
      sortBy: 'code',
      sortDesc: true,
    };
  },
  methods: {
    async initFetch() {
      const param = {
        year: this.filter.year,
        apptype: this.filter.apptype,
        stage: this.filter.stage,
      };
      const promiseFuncs = [
        api.send('/api/cms/judgment/init', param),
      ];
      if (!this.noSelect) {
        promiseFuncs.push(api.send('/api/cms/judgment/applications/list', param));
      }
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      if (!responses) {
        return;
      }
      this.yearList = responses[0].data.yearList;
      if (this.filter.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.apptypeListData = responses[0].data.apptypeListData;
      if (!this.noSelect) {
        this.dbAppList = responses[1].data.appList;
        this.totalRows = this.dbAppList.length;
        this.setBunri(this.filter.apptype);
      }
      this.authAlternate = responses[0].data.authAlternate;
    },
    async fetchAppList() {
      if (this.noSelect) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        year: this.filter.year,
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        bunri: this.filter.bunri,
      };
      const response = await api.send('/api/cms/judgment/applications/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.dbAppList = response.data.appList;
      this.totalRows = this.dbAppList.length;
      this.setBunri(this.filter.apptype);
      this.allRemove();
    },
    filtering(lineData, filter) {
      const bunriMatch = filter.bunri === '' || String(lineData.bunri) === filter.bunri;
      const bunriUnset = lineData.bunri === null && filter.bunri === null;
      const passMatch = filter.pass === '' || String(lineData.pass) === filter.pass;
      const passUnset = lineData.pass === null && filter.pass === null;
      return (passMatch || passUnset) && (bunriMatch || bunriUnset);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsJudgmentSearch/setFilterdIdList', filteredItems);
    },
    allSelect() {
      this.$store.commit('cmsJudgmentSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsJudgmentSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsJudgmentSearch/addSelectedAppid', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsJudgmentSearch/removeSelectedAppid', appId);
      });
    },
    updateSelectedAppIdList(list) {
      this.$store.commit('cmsJudgmentSearch/setSelectedAppid', list);
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
    },
    resetFilter(type) {
      const param = { value: null };
      if (type === 'year') {
        param.key = 'apptype';
        this.$store.commit('cmsJudgmentSearch/setFilter', param);
      }
      param.key = 'stage';
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
      param.key = 'status';
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
      param.key = 'bunri';
      param.value = '';
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
      this.setBunri(this.filter.apptype);
      this.dbAppList = [];
      this.totalRows = this.dbAppList.length;
      if (type === 'apptype') {
        const stageParam = { key: 'stage', value: null };
        // if (this.stageList.length === 1) {
        //   stageParam = { key: 'stage', value: this.stageList[0].value };
        // }
        this.$store.commit('cmsJudgmentSearch/setFilter', stageParam);
      }
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const rePass = this.filter.pass === '' ? 999 : this.filter.pass;
      const params = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        bunri: this.filter.bunri,
        pass: rePass,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/judgment/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.filter.apptype);
      const fileName = `${ymd}_${apptype.text}_${this.filter.stage}次.csv`;
      download.csvUtf(response.data, fileName);
    },
    async exportExcel() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const rePass = this.filter.pass === '' ? 999 : this.filter.pass;
      const params = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        bunri: this.filter.bunri,
        pass: rePass,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/judgment/export/excel', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.filter.apptype);
      const fileName = `${ymd}_${apptype.text}_${this.filter.stage}次.xlsx`;
      download.blob(response.data, fileName);
    },
    // eslint-disable-next-line
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (key === 'done' || key === 'score' || key === 'bunri') {
        if (sortDesc && aRow[key] === null) {
          return -1;
        }
        if (sortDesc && bRow[key] === null) {
          return +1;
        }
      }
      return false;
    },
    async judgment(pass) {
      const text = this.judgmentStatus[pass];
      const count = this.selectedAppIdList.length;
      const msg = `選択中の${count}件の申請を${text}に変更します。`;
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        pass,
        selectedAppIdList: this.selectedAppIdList,
      };
      const response = await api.send('/api/cms/judgment/control/many', params)
        .catch(async (err) => {
          if (err.response.status === 422) {
            let errMsg = '合否設定に失敗しました。エラーの内容は以下です。';
            if (err.response.data.messages) {
              Object.keys(err.response.data.messages).forEach((key) => {
                errMsg += `\n・${err.response.data.messages[key]}`;
              });
            }
            await this.alert(errMsg, false);
          }
        });
      this.$bvModal.hide('judgementModal');
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('合否設定を行いました。', false);
      this.$store.dispatch('page/offWaiting');
      await this.fetchAppList();
    },
    setBunri(value) {
      const targetData = this.apptypeListData[this.filter.year].filter(data => data.id === value);
      if (targetData[0]) {
        this.hasBunri = targetData[0].hasBunri;
      } else {
        this.hasBunri = false;
      }
    },
  },
  computed: {
    isShowStatus() {
      if (this.filter.apptype) {
        const targetData = this.apptypeList.filter(app => app.value === this.filter.apptype);
        return targetData.length > 0 && this.authAlternate.indexOf(targetData[0].text) !== -1;
      }
      return false;
    },
    judgmentStatus() {
      return CONST_STATUS.JUDGMENT_STATUS_OPTION;
    },
    bunriOption() {
      if (!this.hasBunri) {
        return [];
      }
      return ['文', '理'];
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        // let bunri = '';
        // if (application.bunri === 1 || application.bunri === 2) {
        //   bunri = this.bunriOption[application.bunri - 1];
        // }
        let baseAdjust = 0;
        application.adjusts.forEach((adjust) => {
          baseAdjust += adjust.score;
        });
        const sumScore = application.sumScore !== null ? application.sumScore : 0;
        const checkCount = application.checkCount !== null ? application.checkCount : 0;

        let viewScore = 0;
        if (application.done_eval !== 0) {
          viewScore = (baseAdjust * application.done_eval + Number(sumScore)) / (application.done_eval - Number(checkCount));
        }
        let name = '';
        let kana = '';
        if (application.ans_name && application.ans_kana) {
          application.ans_name.forEach((data) => {
            name += `${data.field_answer_text} `;
          });
          application.ans_kana.forEach((data) => {
            kana += `${data.field_answer_text} `;
          });
        }
        return {
          app_id: application.id,
          stage_id: application.stage_id,
          name,
          kana,
          institution: application.ans_belongs.field_answer_text,
          code: application.code,
          count_assign: application.count_assign,
          done: application.done,
          done_eval: application.done_eval,
          status: application.status,
          statusText: CONST_STATUS.APP_STATUS_TEXTS[application.status],
          score: viewScore === 0 ? '' : viewScore,
          pass: application.pass,
          bunri: CONST_APPTYPE.BUNRI[application.bunri],
        };
      });
      return appList;
    },
    filter() {
      return this.$store.state.cmsJudgmentSearch.judgmentFilter;
    },
    selectedAppIdList() {
      return this.$store.state.cmsJudgmentSearch.selectedAppIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsJudgmentSearch.filterdIdList;
    },
    pageOptions() {
      return [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ];
    },
    apptypeList() {
      if (!this.apptypeListData[Number(this.filter.year)]) {
        return [];
      }
      const apptypeList = this.apptypeListData[Number(this.filter.year)].map((data) => {
        return { value: data.id, text: data.name };
      });
      return apptypeList;
    },
    stageList() {
      if (!this.apptypeListData[Number(this.filter.year)] || this.filter.apptype === null) {
        return [];
      }
      const apptypes = this.apptypeListData[Number(this.filter.year)];
      const index = apptypes.findIndex((data) => {
        return this.filter.apptype === data.id;
      });
      if (!apptypes[index] || !apptypes[index].evaluation_stages) {
        return [];
      }
      const stageList = apptypes[index].evaluation_stages.map((data) => {
        return {
          value: data.stage,
          text: `${data.stage}次`,
          is_last: data.is_last,
        };
      });
      return stageList;
    },
    statusText() {
      return CONST_STATUS.APP_STATUS_TEXTS;
    },
    noSelect() {
      const nullYear = this.filter.year === null;
      const nullApptype = this.filter.apptype === null;
      const nullStage = this.filter.stage === null;
      return nullYear || nullApptype || nullStage;
    },
    noDataMsg() {
      if (this.noSelect) {
        return '年度、助成プログラム、審査段階を選択してください。';
      }
      return '条件に一致するデータがありません。';
    },
    judgmentStatusText() {
      return CONST_STATUS.JUDGMENT_STATUS_OPTION;
    },
    judgmentOption() {
      const text = CONST_STATUS.JUDGMENT_STATUS_OPTION;
      const option = [];
      option.push({ value: null, text: '未設定' });
      option.push({ value: '1', text: text['1'] });
      option.push({ value: '0', text: text['0'] });
      option.push({ value: '14', text: text['14'] });
      option.push({ value: '16', text: text['16'] });
      option.push({ value: '15', text: text['15'] });

      return option;
    },
    canExportCsv() {
      return this.filter.apptype && this.filter.stage && this.totalRows > 0;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  #cmsJudgmentTable thead th:nth-of-type(2) {
    width: 250px !important;
  }
  #cmsJudgmentTable thead th:nth-of-type(3) {
    width: 160px !important;
  }
</style>

<style scoped>
  #serch-wrap input, #serch-wrap select {
    height: 50px;
  }

  .year-select {
    height: 40px;
    width: 120px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .search-space {
    margin-right: 15px;
  }
  .search-middle {
    width: 290px;
  }

  .search-middle-long {
    width: 300px;
  }
  #judgementModal___BV_modal_outer_ {
    z-index: 999 !important;
  }
</style>
